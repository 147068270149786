<template>
  <header class="nav-after">
    <div class="container">
      <a class="logo-header" href="/" title="retour à l'accueil">
        <img src="../assets/svg/logo/Logo_Autoref.svg" />
      </a>
    </div>
  </header>
</template>

<script>
import navigationSecondaire from "@/components/comp.navigationSecondaire.vue";

export default {

  name: "entete",
  components: {
    navigationSecondaire,
  }
};
</script>
