/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import PageIndex from '../views/Index.vue'
const routes = [
  {
    path: '/',
    name: 'index',
    component: PageIndex 
  },
  {
    path: '/portailVL',
    name: 'portailVL',
    component: () => import('../views/PortailVL.vue')
  },
  {
    path: '/categorieTecdoc',
    name: 'categorieTecdoc',
    component: () => import('../views/categorieTecdoc.vue')
  },
  {
    path: '/ficheArticle',
    name: 'ficheArticle',
    component: () => import('../views/ficheArticle.vue')
  },
  {
    path: '/listeArticle',
    name: 'listeArticle',
    component: () => import('../views/listeArticle.vue')
  },
  {
    path: '/monpanier',
    name: 'monpanier',
    component: () => import('../views/panier.vue')
  },
  {
    path: '/monCompte',
    name: 'monCompte',
    component: () => import('../views/monCompte.vue')
  },
  {
    path: '/mesListes',
    name: 'mesListes',
    component: () => import('../views/mesListes.vue')
  },
  {
    path: '/portailPL',
    name: 'portailPL',
    component: () => import('../views/PortailPL.vue')
  },
  {
    path: '/PageNonTrouvee',
    name: 'PageNonTrouvee',
    component: () => import('../views/pageNonTrouvee.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router