<template>

  <!-- En-tête -->
  <!-- <span class = "d-block p-2 bg-primary"></span> -->
  <entete></entete>
  <!-- <span class="d-block p-2 bg-dark"></span> -->
  <main>

    <form class="container">
      <div class="row-inputs">
        <div class="row row-label">
          <label for="log_log">Identifiant</label>
          <label for="pwd_log">Mot de passe</label>
        </div>
        <div class="column md-row row-form">
          <input type="text" class="form-control fs21" placeholder="Identifiant" v-model="log_log"
            @keyup.enter="focusSuivant('pwd')" v-focus id="log_log" />
          <input type="password" class="form-control" placeholder="Mot de passe" v-model="pwd_log" id="pwd_log"
            ref="pwd" @keyup.enter="focusSuivant('connexion')" />
          <button type="button" id="connexion" ref="connexion" class="btn btn-plain btn-connexion"
            @keyup.enter="VerifierLogin()" @click="VerifierLogin()">
            Se connecter
          </button>
        </div>
        <div class="md-row row-form-create">
          <button type="button" id="creercompte" class="btn btn-outline btn-account">
            Créer un compte
          </button>
        </div>
        <!-- ERREUR DE SAISIE - AFFICHAGE DU MESSAGE D'ERREUR POUR L'UTILISATEUR -->
        <div class="alert alert-danger" v-if="retourError">
          Identifiant ou mot de passe incorrect
        </div>
      </div>
      <div class="column md-row row-textuel">
        <div class="col-intro col-100 col-md-60">
          <h1>Un webshop conçu pour les pros</h1>
          <p>
            Qui vous permet de trouver rapidement la bonne pièce au bon prix pour
            votre réparation.<br />Plus d'éfficacité, plus de services, plus de
            garanties pour gérer votre activité avec sérénité et professionnalisme.
          </p>
        </div>
        <div class="col-video col-100 col-md-50">
          <figure>
            <img src="../assets/jpg/thumbnail_video_Close_Resize.jpg" class="img-featured" />
          </figure>
          <a class="link-video" href="/" title="En savoir plus sur le Webshop">Voir la vidéo de présentation <i
              class="icon icon-lecture"></i></a>
        </div>
      </div>
    </form>

  </main>
  <competence></competence>

</template>

<script>
import { useRouter } from "vue-router";
import entete from "@/components/comp.entete.vue";
import competence from "@/components/comp.competence.vue";
const router = useRouter();
export default {
  name: "index",

  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  components: {
    entete,
    competence,
  },
  data() {
    return {
      log_log: "",
      pwd_log: "",
      retourError: false,
    };
  },
  methods: {
    focusSuivant(nextRef) {
      this.$refs[nextRef].focus();
    },
    VerifierLogin: function () {
      var prog = "./src/req/VerifierLogin.php";
      // envoie des données dans le POSTS
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          log_log: this.log_log,
          pwd_log: this.pwd_log,
        }),
      };
      fetch(prog, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.retour == "OK") {
            this.$sessionStorage.set("log_log", this.log_log),
              this.$sessionStorage.set("pwd_log", this.pwd_log),
              this.$sessionStorage.set("instance", data.ins_adh),
              this.$sessionStorage.set("id_adherent", data.id_adherent),
              this.$sessionStorage.set("id_magasin", data.id_magasin),
              this.$sessionStorage.set("id_fiadd", data.id_fiadd),
              this.$sessionStorage.set("id_fiaddc", data.id_fiaddc),
              this.$sessionStorage.set("etb_mag", data.etb_mag),
              this.$sessionStorage.set("vil_mag", data.vil_mag),
              this.$sessionStorage.set("ad1_mag", data.ad1_mag),
              this.$sessionStorage.set("ad2_mag", data.ad2_mag),
              this.$sessionStorage.set("sir_mag", data.sir_mag),
              this.$sessionStorage.set("tel_mag", data.tel_mag),
              // MISE EN MEMOIRE DANS LE STORE
              this.$store.commit('setglinkingTargetTypeDefaut', data.linkingTargetTypeDefaut);
            this.$store.commit('setgcod_metDefaut', data.cod_metDefaut);
            this.$store.commit('setgGpi_adh', data.gpi_adh);
            this.$store.commit('setgPic_adh', data.pic_adh);
            this.$store.commit('setgGart_adh', data.gart_adh);
            // Chargement du style si PL ou VL
            if (data.cod_metDefaut == 'PL') {
              document.body.classList.add('pl');
            }

            //this.$router.push({ name: "portail", query: { id_filogin: 0 } });
            this.$router.push({ name: "portailVL" });
          } else {
            //ERREUR DE SAISIE
            this.retourError = true;
            this.log_log = "";
            this.pwd_log = "";
            document.getElementById("log_log").focus();
          }
        });
    },
  },
};
</script>
