<template>
    <footer>
        <skills></skills>
    </footer>
</template>

<script>
import skills from "@/components/comp.skills.vue";
export default {
    name: "competence",

    components: {
        skills,
    },

};
</script>
