<template>

    <ul class="container-skills">
        <li class="col-30 col-md-20">
            <figure><img src="../assets/svg/icones/home/argument_de_vente/equipementiers.svg" alt="Équipementier" />
            </figure>
            <p>Les plus grands<br>équipementiers</p>
        </li>
        <li class="col-30 col-md-20">
            <figure><img src="../assets/svg/icones/home/argument_de_vente/origine.svg" alt="Pièce d\'origine" />
            </figure>
            <p>Pièces de rechange<br>d'origine garantie</p>
        </li>
        <li class="col-30 col-md-20">
            <figure>
                <img src="../assets/svg/icones/home/argument_de_vente/stock.svg" alt="Des milliers de références" />
            </figure>
            <p>Des miliers de<br>références en stock</p>
        </li>
        <li class="col-30 col-md-20">
            <figure><img src="../assets/svg/icones/home/argument_de_vente/securise.svg" alt="Paiement sécurisé" />
            </figure>
            <p>Paiement sécurisé<br>et rapide</p>
        </li>
        <li class="col-30 col-md-20">
            <figure>
                <img src="../assets/svg/icones/home/argument_de_vente/SAV.svg"
                    alt="Services Après-Vente au quotidient" />
            </figure>
            <p>Services Après-Vente<br>au quotidient</p>
        </li>
    </ul>

</template>
<script>
export default {
    name: "skills",
};
</script>